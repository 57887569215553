import React from 'react';
import logo from '../assets/logo.svg';

const AgreementsRu = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
          <p><strong>СОГЛАШЕНИЕ О РАЗРЕШЕНИИ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ «ROAD24»</strong></p>

          <p>Настоящее соглашение по обработке персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую мобильное приложение «Road24» (далее – «Road24» или сайт Road24.uz) может получить о Пользователе мобильного приложения «Road24» во время пользования мобильным приложением и его функциями.<br />
              <strong>1. Определение терминов</strong><br />
              1.1. Персональные данные — зафиксированная на электронном, бумажном и (или) ином материальном носителе информация, относящаяся к определенному физическому лицу или дающая возможность его идентификации;<br />
              Субъект персональных данных (субъект) — физическое лицо, к которому относятся персональные данные;<br />
              База персональных данных — база данных в виде информационной системы, содержащая в своем составе персональные данные;<br />
              1.2. Обработка персональных данных — реализация одного или совокупности действий по сбору, систематизации, хранению, изменению, дополнению, использованию, предоставлению, распространению, передаче, обезличиванию и уничтожению персональных данных;<br />
              1.3. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.<br />
              1.4. «Пользователь «Road24» – лицо, имеющее доступ к сайту («Road24».uz), мобильному приложению посредством сети Интернет и использующее информацию, материалы и продукты «Road24».<br />
              1.5. «Услуги по оплате штрафов» - продукт, который Пользователь получает в мобильном приложении или на сайте и оплачивает через платежную систему, используемую в «Road24».<br />
              1.6. «Оператор персональных данных» или «Оператор» - Общество с Ограниченной Ответственностью «KASH APP» как владелец мобильного приложения «Road24».<br />
              1.7. Стороны удостоверяют, что не подлежат регистрации базы персональных данных, содержащие в своем составе персональные данные: сделанные Пользователем общедоступными.<br />
              1.8. Пользователь после утверждения персональных данных дает свое согласие на обработку своих данных и делает их общедоступными.</p>

          <p><strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong><br />
              2.1. Использование Cайта ( «Road24».uz ), приложения «Road24» Пользователем означает согласие с условиями настоящего соглашения и условиями обработки персональных данных Пользователя.<br />
              2.2. В случае несогласия с условиями настоящего соглашения, Пользователь должен прекратить использование мобильного приложения «Road24» и Сайта (Road24.uz). Регистрация на мобильном приложении «Road24» Пользователя означает согласие субъекта персональных данных условиями настоящего соглашения.<br />
              2.3. Настоящий документ устанавливает права и обязанности Сторон по обработке персональных данных, которые Пользователь предоставляет по запросу Оператора при регистрации на Сайте, при подписке на информационную e-mail рассылку или при оформлении заказа.<br />
              2.4. Персональные данные, разрешённые к обработке в рамках настоящего Соглашения, предоставляются Пользователем путём заполнения форм в мобильном приложении «Road24» и включают в себя следующую информацию:<br />
              2.4.1. Ф.И.О. и серия паспорта Пользователя;<br />
              2.4.2. Контактный телефон Пользователя;<br />
              2.4.3. Адрес электронной почты (e-mail) Пользователя;<br />
              2.4.4. Пол Пользователя;<br />
              2.4.5. Дата рождения Пользователя;<br />
              2.4.6 Сведения об Автомобиле Пользователя, а именно:<br />
              • Государственный Номерной знак автомобиля с указанием (территориального кода, серии, Регистрационного номера и идентификатора страны);<br />
              • Серия Технического паспорта и его номер;<br />
              • Марка и модель автомобиля Пользователя.<br />
              2.4.6.1. Иные данные об автомобиле Пользователя указанные в разделе MyAuto» в мобильном приложении «Road24», а именно:<br />
              • Сведения о Страховке Автомобиля Пользователя;<br />
              • Сведения о проведении Технического осмотра Автомобиля Пользователя и месте, где был проведён Тех. Осмотр;<br />
              • Сведения о Доверенности на Автомобиль Пользователя с указанием лиц, которые имеют право пользованием данным транспортным средством и иными данными (период действия Доверенности, Типом Доверенности);<br />
              • Сведения о наличии Тонировки в Автомобиле Пользователя с дополнительными сведениями (период действия, Тип тонировки и иные сведения). Для предоставления данной опции, Оператор установит специальную связь с другими организациями, в том числе my.gov.uz. <br />
              • Сведения о замене Машинного масла Автомобиля Пользователя с дополнительными сведениями;<br />
              2.4.7. Данные о штрафах на Автомобиль Пользователя с указанием следующих данных:<br />
              • Количества полученных штрафов на Автомобиль Пользователя;<br />
              • Истории оплаченных и неоплаченных штрафов;<br />
              • Детализации штрафа с указанием след. сведений:<br />
              • Номера постановления штрафа;<br />
              • Изображения Автомобиля Пользователя в момент нарушения правил Дорожного движения с изображением Государственного Номерного знака автомобиля;<br />
              • Марки Автомобиля Пользователя;<br />
              • Описания нарушения на основании Статьи из Кодекса Республики Узбекистан об Административной Ответственности;<br />
              • Место нарушения правил дорожного движения с указанием точного адреса;<br />
              • Дата совершения нарушения правил дорожного движения;<br />
              • Номер Статьи из Кодекса Республики Узбекистан об Административной Ответственности;<br />
              • Сумма штрафа указанной в государственной валюте (Узбекский сум);<br />
              • Цифровой документ Штрафа в формате PDF с указанием всех сведений, описанных выше.<br />
              • Сведения о наличии штрафов<br />
              2.4.8. Данные о геолокации Пользователя для использования следующих функций мобильного приложения «Road24»:<br />
              • Отправки Репорта о нарушении правил Дорожного движения другими водителями с указанием Геолокации, Видео файла где это нарушение зафиксировано, дата и время данного события;<br />
              2.4.9. Реквизиты банковской карты Пользователя для проведения финансовых операций по следующим функциям:<br />
              • Оплата штрафа за нарушение правил дорожного движения, зафиксированных за Автомобилем Пользователя с возможностью частичного или полного погашения. Сведения об оплате штрафа будут доступны пользователю в разделе «MyAuto > Штрафы > Не оплачено > История оплат», а также в разделе «Уведомления» в мобильном приложении «Road24».<br />
              • Получение вознаграждения за фиксацию нарушения правил дорожного движения в разделе «Репорты» в мобильном приложении «Road24».<br />
              • Для предоставления возможности пользования Пользователем, Оператор имеет право устанавливать специальную связь с другими организациями, в том числе my.gov.uz.<br />
              • В случае покупки опций (разрешительных документов) через данное приложение, Пользователь принимает и соглашается с ценой покупки, которая состоит из стоимости услуг Единого портала интерактивных государственных услуг и расходов Оператора.</p>

          <p><strong>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong><br />
              3.1. Персональные данные Пользователя Оператор вправе использовать в целях:<br />
              3.1.1. Идентификации Пользователя, зарегистрированного в мобильном приложении «Road24», оформления репорта о нарушениях Правил Дорожного Движения другими водителями и других действий в рамках мобильного приложения «Road24».<br />
              3.1.2. Предоставление Пользователю доступа к персонализированным данным мобильного приложения «Road24» и Сайта (Road24.uz).<br />
              3.1.3. Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования мобильного приложения «Road24» и Сайта (Road24.uz), оказания услуг и обработки репортов от Пользователя.<br />
              3.1.4. Создание учетной записи для использования частей мобильного приложения «Road24» и Сайта (Road24.uz), если Пользователь дал согласие на создание учетной записи.<br />
              3.1.5. Предоставление Пользователю специальных предложений, информации о товарах и услугах Оператора, партнеров Оператора, новостная рассылка.<br />
              3.1.6. Предоставление Пользователю дополнительных услуг по страхованию и тонировки и др.<br />
              3.2. После регистрации Пользователя на мобильном приложении «Road24» и на Сайте (Road24.uz), Оператор имеет право использовать персональные данные Пользователя для реализации действий, предусмотренных настоящим соглашением.<br />
              3.3. Пользователь принимает и соглашается с тем, что он может покупать тонировку, страховой полис, другие разрешительные документы и оплатить штраф через данное приложение. В свою очередь, Оператор станет платформой между пользователем и Единым порталом интерактивных государственных услуг.</p>

          <p><strong>4. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong><br />
              4.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.<br />
              4.2. Пользователь соглашается с тем, что Оператор вправе передавать персональные данные третьим лицам исключительно в целях обработки, предусмотренных настоящим соглашением.<br />
              4.3. Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>

          <p><strong>5. РАСТОРЖЕНИЕ СОГЛАШЕНИЯ</strong><br />
              Пользователь вправе отозвать свое согласие на обработку персональных данных, направив уведомление об этом Оператору в Telegram: https://t.me/road24yordam, за исключением случаев, когда персональные данные Пользователя необходимы Оператору для исполнения обязательств перед Пользователем.</p>

          <p>
              <strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН</strong><br />
              6.1. Оператор, виновно не исполнивший свои обязательства, несёт ответственность за реальный ущерб, понесенный Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Узбекистан. При это реальный ущерб Пользователя должен быть документально доказан.<br />
              6.2. Оператор не несет ответственности перед Пользователем за любые убытки, произошедшие из-за: использования либо невозможности использования мобильного приложения «Road24» и Сайта («Road24».uz); несанкционированного доступа к коммуникациям Пользователя; поведения любого третьего лица на Сайте.<br />
              6.3. Оператор не несет ответственность за какую-либо информацию, размещенную Пользователем в мобильном приложении «Road24» и Сайте («Road24».uz).<br />
              <strong>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong><br />
              7.1. Оператор вправе вносить изменения в настоящее соглашение и в Политику конфиденциальности без согласия Пользователя.<br />
              7.2. Политика конфиденциальности вступает в силу с момента ее размещения в мобильном приложении «Road24» и на Сайте («Road24».uz)</p>
      </div>
    </>
  );
}

export default AgreementsRu;
